<template>
  <div v-if="type === 'image'">
    <a :href="file" target="_blank" class="">
      <img :src="file" alt="image" class="img-thumbnail mw-125px" />
    </a>
  </div>
  <!--    <video-->
  <!--      v-else-if="type === 'video'"-->
  <!--      class="img-thumbnail mw-200px"-->
  <!--      width="320"-->
  <!--      height="240"-->
  <!--      autoplay-->
  <!--    >-->
  <!--      <source :src="file" type="video/mp4" />-->
  <!--      <source :src="file" type="video/ogg" />-->
  <!--      Your browser does not support the video tag.-->
  <!--    </video>-->

  <!--    <audio v-else-if="type === 'audio'" controls>-->
  <!--      <source :src="file" type="audio/ogg" />-->
  <!--      <source :src="file" type="audio/mpeg" />-->
  <!--      Your browser does not support the audio element.-->
  <!--    </audio>-->
  <a
    v-else
    :href="file"
    class="btn btn-sm btn-outline-primary text-nowrap"
    target="_blank"
  >
    <i class="bi bi-paperclip me-1"></i>{{ translate("View the file") }}</a
  >
</template>

<script>
import { defineComponent } from "vue";
import { translate } from "@/core/services/HelpersFun";

export default defineComponent({
  name: "kt-file-viewer",
  props: {
    file: { type: String, default: "" },
    type: { type: String, default: "" },
  },
  setup() {
    return {
      translate,
    };
  },
});
</script>
